import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from '../Slick/CustomArrows'
import Image from '../Images'
import Style from '../../styles/components/banner.wakastuces.module.scss'

const Wakastuces = (props) => {
  const items = props.items;
  const slides = props.viewportSize !== 'large' ? 1 : 2;
  const dots = props.viewportSize !== 'large';
  const settings = {
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: slides,
    dots: dots
  };

  return (
    <section className="Wakastuces">
      <div className={Style.section}>
        <h2>{props.title.text}</h2>
        <div 
          className={Style.description} 
          dangerouslySetInnerHTML={{__html: props.description.html}}>
        </div>
        {
          props.viewportSize === 'large' && 
          <div className={Style.background}>
            <Image src={`${props.background.url}`} alt="Wakastuces" transparent />
          </div>
        }
        <Slider {...settings}>
          { items.map((item, index) => {
            return (
              <div key={`wakastuce_${index}`} className={Style.items}>
                <div className={Style.imageWrapper}>
                  <Image src={`${item.image.url}`} alt={item.title.text} />
                </div>
                <div className={Style.textWrapper}>
                  <h3>{item.title.text}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.description.html }}></div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(Wakastuces);