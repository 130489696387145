import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby'
import Layout from '../layouts/Index';
import HowItWorks from '../components/HowItWorks';
import Wakastuces from '../components/Banners/Wakastuces';
import FaqPageSection from '../components/FaqPageSection';
import SEO from '../components/SEO';
import { handleModal } from '../actions/modal';
import { handleModalLanding } from '../actions/modalLanding';

class HowItWorksPage extends React.Component {

  componentDidMount() {
    const featuredLandingPage = this.props.data.popupLandingStory.edges.filter((item) => {
      return JSON.parse(item.node.data.featured_content);
    });

    const landingStory = featuredLandingPage.length > 0 ? (() => {
        const story = featuredLandingPage[0].node.data.stories[0].story.document[0].data;
        return {
          id: story.project_id,
          projectId: story.project_id,
          name: story.title.text,
          isBook: story.physical_book,
          popupBackground: story.popup_background,
          pageTracking: 'ccm'
        }
      }
    ) : (() => {});

    this.landingStory = landingStory();

    setTimeout(() => {
      window.addEventListener('scroll', this.handleLandingPopup, { passive: true });
    }, 1000);
  }

  handleLandingPopup = (e) => {
    if (typeof window === 'undefined' || !window.__popupTrigger || typeof this.landingStory === 'undefined') {
      return false;
    }
    
    if (!this.props.modalLanding) {
      if (window.scrollY > 620) {
        window.removeEventListener('scroll', this.handleLandingPopup, { passive: true });
        this.props.handleModalLanding({ modalLanding: true })
        this.props.handleModal({
          modal: !this.props.modal,
          story: this.landingStory
        });
      }
    }
  }

  render() {
    const { 
      data: { howItWorks, wakastuces, faq, staticText } 
    } = this.props;
    
    const data = {
      howitworks: howItWorks.edges[0].node.data,
      wakastuces: wakastuces.edges[0].node.data,
      faq: faq.edges[0].node.data
    }
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    return (
      <Layout 
        context={this.props.pageContext} 
        pageTitle={this.props.pageContext.title}
        _t={staticPrismicTexts}
      >
        <SEO 
          pageTitle={data.howitworks.seo__meta_title_.text} 
          pageDescription={data.howitworks.seo__meta_description_.text} 
          context={this.props.pageContext} 
        />
        
        <div className="page">
          <HowItWorks 
            animate={false} 
            {...howItWorks} 
            pageData={data.howitworks}
            context={this.props.pageContext} 
            _t={staticPrismicTexts}
          />
          <Wakastuces {...data.wakastuces} twic={this.props.pathContext.twic} />
          <FaqPageSection {...data.faq} />
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query HowItWorksPage($locale: String!) {
    howItWorks: allPrismicSectionHowitworks(filter: { lang: { eq: $locale} }) {
      edges { ...howItWorks }
    }
    wakastuces: allPrismicBlockWakastuces(filter: { lang: { eq: $locale} }) {
      edges { ...wakastuces }
    }
    faq: allPrismicSectionFaq(filter: { lang: { eq: $locale} }) {
      edges { ...faq }
    }
    popupLandingStory: allPrismicPageLanding(
      filter: {
        data: {
          enabled: {eq: "true"}
        }
        lang: { eq: $locale}
      }
    ) {
      edges {
        node {
          lang
          data {
            featured_content
            stories {
              story {
                document {
                  data {
                    project_id
                    title {
                      text
                    }
                    physical_book
                    popup_background {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;


const mapStateToProps = (state) => ({
  ...state.modal,
  ...state.modalLanding
});

const mapDispatchToProps = (dispatch) => ({
  handleModal: (state) => dispatch(handleModal(state)),
  handleModalLanding: (state) => dispatch(handleModalLanding(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(HowItWorksPage);